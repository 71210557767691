import { useContext } from "react"
import { CartContext } from "../../providers/cartProvider"
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg'
import { Link } from "react-router-dom"
import { THEMES } from "../../data/themes.mjs"
import { defaultTheme } from "../../config.mjs"
import { CartPanelStyle } from "../style"

export function CartPanel({ hover, setHover }) {
    const style = CartPanelStyle()
    const cart = useContext(CartContext)
    const theme = THEMES[defaultTheme]

    const removeHover = () => {
        if(hover) setHover(false)
    }
    
    return (
        <div style={style.container} className={hover ? "cart-panel hovered" : "cart-panel"} onMouseLeave={() => removeHover()}>
            <div style={style.inner}>
                <div style={style.title}>Shopping Cart</div>
                <div>
                    { cart.items.length === 0 && <div style={style.noItems}>
                        There are no items in your Shopping Cart
                    </div> }
                    { cart.items.length > 0 && cart.items.map((prod, id) => {
                        return <div key={id} style={{...style.itemContainer, borderBottom: id === cart.items.length - 1 ? "none" : `1px solid ${theme.pageText}`}}>
                            <DeleteIcon style={style.delete} id={id} className={'header-hover'} onClick={() => cart.removeItem(id)} />
                            <div style={style.detailsContainer}>
                                <div style={style.name}>{prod.productName} {prod.productType}</div>
                                { prod.year && prod.make && prod.model && <div style={style.details}>{prod.year} {prod.make} {prod.model}</div> }
                                { prod.colorName && <div style={style.details}>Color: {prod.colorName}</div> }
                                { prod.row && <div style={style.details}>Row: {prod.row}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quantity: 1</div> }
                                { !prod.row && <div style={style.details}>Quantity: 1</div> }
                            </div>
                            <div style={style.cost}>${prod.price.toFixed(2)}</div>
                        </div>
                    })}
                </div>
                <Link to={'/shopping-cart/'} style={style.button} className={'button-hover'}>Go to Shopping Cart</Link>
            </div>
        </div>
    )
}