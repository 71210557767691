import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { THEMES } from "../../data/themes.mjs";
import { defaultTheme } from "../../config.mjs";

export function ContainerStyle() {
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: "100%",
            maxWidth: "1100px",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            justifyContent: "center",
            gap: "4vw"
        },
        inputsContainer: {
            width: "100%",
            maxWidth: "700px",
            padding: "28px 24px",
            display: "flex",
            flexDirection: "column",
            color: "#333333" /* Colors the options in select dropdown */
        }
    }
}

export function InputStyle() {
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            border: `1px solid ${theme.pageText}`,
            borderRadius: '8px',
            overflow: 'hidden'
        },
        header: {
            padding: '12px 24px',
            width: '100%',
            fontSize: "24px",
            fontWeight: '500',
            backgroundColor: theme.componentBackground,
            borderBottom: `1px solid ${theme.pageText}`,
            color: theme.componentHeader
        },
        form: {
            display: "flex",
            flexDirection: "column",
            height: fonts.mobile ? "auto" : "527px",
            maxHeight: fonts.mobile ? "none" : "527px",
            gap: "0 20px",
            padding: fonts.mobile ? "16px 16px 32px" : "40px",
            transitionDuration: ".3s",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
            maxWidth: "100%"
        },
        inputOuter: {
            display: "flex",
            width: "100%",
            maxWidth: "100%",
            gap: "0 14px"
        },
        inputContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            flex: "2",
            minWidth: "0"
        },
        input: {
            fontSize: fonts.mobile ? "16px" : "14px",
            borderColor: theme.pageText
        },
        label: {
            ...fonts.standard,
            fontWeight: "500",
            lineHeight: "24px",
            color: theme.pageText
        },
        error: {
            ...fonts.small,
            color: "red",
            minHeight: "16px",
            fontWeight: "600"
        },
        button: {
            width: "100%",
            height: "55px",
            display: "flex",
            borderRadius: "4px",
            justifyContent: "center",
            alignItems: "center",
            margin: "24px 0",
            fontSize: "16px",
            fontWeight: "500",
            color: theme.buttonText,
            backgroundColor: theme.buttonBackground,
            textTransform: "uppercase",
            cursor: "pointer",
            outlline: 'none',
            border: "none",
            fontFamily: "'Montserrat', sans-serif",
            transitionDuration: ".3s"
        },
        item: {
            minHeight: "24px",
            fontSize: fonts.mobile ? "16px" : "14px",
            padding: "0",
            borderRadius: "0",
            border: `1px solid ${theme.pageText}`
        },
        orContainer: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
            margin: fonts.mobile ? "4px 0" : "18px 0"
        },
        orLine: {
            width: "33%",
            height: "1px",
            backgroundColor: theme.pageText
        },
        orText: {
            ...fonts.standard,
            color: theme.pageText
        },
        payByCreditContainer: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "14px",
            paddingBottom: "14px",
            marginTop: fonts.mobile ? "14px" : "0"
        },
        payByCredit: {
            fontSize:"18px",
            color: theme.pageText,
            fontWeight: "600",
            textTransform: "uppercase"
        }
    }
}

export function ProcessingStyle() { 
    const fonts = useContext(FontContext)
    
    return {
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: "200",
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            alignItems: "center",
            paddingTop: "25vh",
            backgroundColor: "rgba(255, 255, 255, .9"
        },
        text: {
            ...fonts.h2
        }
    }
}

export function ErrorBoxStyle() { 
    return {
        background: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            backgroundColor: "rgba(0, 0, 0, .5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "200"
        },
        container: {    
            position: "relative",
            zIndex: "100",
            width: "300px",
            padding: "48px",
            background: "#ffffff",
            display: "flex",
            flexDirection: "column",
            gap: "48px",
            borderRadius: "10px",
            textAlign: "center",
            alignItems: "center"
        },
        icon: {
            width: "98px",
            height: "98px"
        },
        title: {
            fontSize: "28px",
            fontWeight: "500",
            color: "#0f0f0f",
            paddingBottom: "12px",
            textTransform: "uppercase"
        },
        text: {
            whiteSpace: "normal",
            fontSize: "14px",
            fontWeight: "500",
            color: "#4a4a4a",
            lineHeight: "20px"
        },
        button: {
            width: "205px",
            height: "45px",
            display: "flex",
            backgroundColor: "#de4c5f",
            fontSize: "18px",
            fontWeight: "400",
            color: "#ffffff",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            textTransform: "uppercase",
            border: "none",
            cursor: "pointer"
        }
    }
}

export function SummaryStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: fonts.mobile ? "100%" : "340px",
            minWidth: fonts.mobile ? "100%" : "340px",
            padding: fonts.mobile ? "28px 24px 14px" : "28px 24px 56px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            backgroundColor: fonts.mobile ? 'transparent' : theme.componentBackground,
            alignSelf: "flex-start"
        },
        title: {
            ...fonts.h2,
            padding: "12px 0 8px",
            color: theme.componentHeader
        },
        allLines: {
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            color: theme.componentText
        },
        lineContainer: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "400",
            display: "flex",
            justifyContent: "space-between"
        },
        blankLine: {
            width: "100%",
            height: "1px",
            backgroundColor: theme.componentText,
            margin: fonts.mobile ? "4px 0" : "16px 0"
        },
        discountContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "14px"
        },
        discountTitle: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "400"
        },
        inputContainer: {
            display: "flex",
            gap: "12px",
            alignItems: "center"
        },
        discountInput: {
            padding: "0 14px",
            width: "200px",
            maxWidth: "200px",
            height: "40px",
            border: `1px solid ${theme.componentText}`
        },
        discountButton: {
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.pageBackground,
            border: `2px solid ${theme.pageHeader}`,
            color: theme.pageHeader,
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "uppercase",
            cursor: "pointer",
            outline: "none",
            boxShadow: "none",
            borderRadius: "8px",
            transitionDuration: ".3s"
        },
        discountMessage: {
            height: "18px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            fontWeight: "600",
            color: theme.componentHeader
        },
        removeDiscount: {
            marginLeft: "16px",
            color: theme.buttonBackground,
            cursor: "pointer",
            fontWeight: "500",
            transitionDuration: ".3s"
        },
        showDiscount: {
            fontSize: "12px",
            fontWeight: "600",
            color: theme.specialBackground,
            marginTop: "-4px",
            width: "100%",
            textAlign: "right"
        }
    }
}