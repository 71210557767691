import React, { useContext, useState } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import ErrorPopup from "./errorPopup";
import { CartContext } from "../../../providers/cartProvider";

export default function Paypal({client, submitOrder}) {
    const apiAddress = process.env.REACT_APP_API_SERVER + '/web/order/retail/paypal/'
    const cart = useContext(CartContext)
    const [message, setMessage] = useState("")
    const style = {shape: "rect", layout: "vertical"}

    const initialOptions = {
        "client-id": client,
        "components": "buttons",
        intent: "capture",
        currency: "USD",
        "disable-funding": "paylater,venmo,card"
    };

    const createOrder = async() => {
        const itemList = cart.getItemList()
        
        return await fetch(apiAddress, {
            method: "POST",
            headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
            body: JSON.stringify({
                cart: itemList,
                subtotal: cart.subTotal,
                tax: cart.tax,
                discount: cart.discount
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.id) return data.id;
            else {
                const errorDetail = data?.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${data.debug_id})`
                    : data.payload.toString();
                throw new Error(errorMessage);
            }
        })
        .catch(() => setMessage('PayPal payments are temporarily unavailable.'));
    }

    const onApprove = async(data, actions) => {
        await fetch(apiAddress + data.orderID, {
            method: "POST",
            headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
        })
        .then(async (response) => {
            if(!response.ok) throw new Error(response.statusText || 'PayPal Transaction Error');
            return await response.json()
        })
        .then((data) => {
            const errorDetail = data?.details?.[0]
            if(errorDetail) setMessage(errorDetail)
            else {
                const transaction = data.purchase_units[0].payments.captures[0];
                submitOrder({}, transaction.id)
            }
        })
        .catch((error) => {
            setMessage(error.toString())
            return actions.restart();
        })
    }

    const showError = (data) => {
        //console.log(data.toString())
    }

    return (
        <div style={{width: "100%", marginTop: "20px"}}>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons style={style}
                    forceReRender={[style]}
                    createOrder={createOrder}
                    onError={showError}
                    onApprove={onApprove}
                />
            </PayPalScriptProvider>
            { message && message !== "" && <ErrorPopup message={message} setMessage={setMessage} />}
        </div>
    );
}