import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { THEMES } from "../../data/themes.mjs";
import { defaultTheme } from "../../config.mjs";

export function CartPageStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)

    return {
        container: {
            width: "100%",
            maxWidth: "1100px",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            justifyContent: "center",
            gap: "4vw"
        },
        titleContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        title: {
            ...fonts.h2,
            padding: "12px 0 8px",
            color: theme.pageHeader
        },
        shippingTitle: {
            ...fonts.h3,
            padding: "24px 0 18px",
            color: theme.pageHeader
        },
        shippingText: {
            ...fonts.standard,
            color: theme.pageText
        },
        shippingTextDos: {
            ...fonts.standard,
            fontWeight: "600",
            marginTop: "8px",
            fontStyle: "italic",
            color: theme.pageText
        }
    }
}

export function CartStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: "100%",
            maxWidth: "700px",
            padding: "28px 24px",
            display: "flex",
            flexDirection: "column",
            color: theme.fontColorA
        },
        itemContainer: {
            display: "flex",
            gap: fonts.mobile ? "8px" : "16px",
            padding: "24px 0"
        },
        image: {
            backgroundColor: theme.previewImageBackground,
        },
        detailsContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: fonts.mobile ? "2px" : "6px"
        },
        name: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "600",
            color: theme.pageHeader
        },
        details: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "500",
            color: theme.pageText
        },
        costContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center"
        },
        cost: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            color: theme.pageHeader,
            fontWeight: "600",
            display: "flex",
            gap: "8px",
        },
        delete: {
            width: fonts.mobile ? "24px" : "32px",
            height: fonts.mobile ? "24px" : "32px",
            stroke: theme.pageText,
            color: theme.pageText,
            cursor: "pointer"
        },
        noItems: {
            ...fonts.large,
            padding: "12px 0",
        }
    }
}

export function SummaryStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: fonts.mobile ? "100%" : "340px",
            padding: "28px 24px 56px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            backgroundColor: theme.componentBackground,
            alignSelf: "flex-start"
        },
        title: {
            ...fonts.h2,
            padding: "12px 0 8px",
            color: theme.componentHeader
        },
        allLines: {
            display: "flex",
            flexDirection: "column",
            gap: "8px"
        },
        lineContainer: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "400",
            display: "flex",
            justifyContent: "space-between",
            color: theme.componentText
        },
        blankLine: {
            width: "100%",
            height: "1px",
            backgroundColor: theme.componentText,
            margin: "16px 0"
        },
        checkout: {
            ...fonts.h3,
            color: theme.buttonText,
            width: "100%",
            height: "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.buttonBackground,
            textDecoration: "none",
            cursor: "pointer",
            transitionDuration: ".3s"
        },
        shopping: {
            ...fonts.h3,
            color: theme.pageHeader,
            width: "100%",
            height: "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.pageBackground,
            border: `2px solid ${theme.pageHeader}`,
            textDecoration: "none",
            cursor: "pointer",
            transitionDuration: ".3s"
        },
        noCheckout: {
            ...fonts.h3,
            backgroundColor: theme.componentBackground,
            color: theme.pageBackground,
            border: `2px solid ${theme.pageBackground}`,
            width: "100%",
            height: "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            cursor: "pointer"
        },
        discountContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "14px"
        },
        discountTitle: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "400"
        },
        inputContainer: {
            display: "flex",
            gap: "12px",
            alignItems: "center"
        }
    }
}