import { useContext, useState } from "react"

import { Modal } from "./components/modal"
import { PrecheckContent } from "./components/precheck";
import { PrecartContext } from "../../providers/precartProvider";
import { PrecheckHeader } from "./components/header";
import { PrecheckButton } from "./components/button";
import { useNavigate } from "react-router-dom";
import { upgradedConsoleCost } from "../../config.mjs";

export function Precheck({ product }) {
    const navigate = useNavigate()
    const precart = useContext(PrecartContext)
    const [currentPage, setCurrentPageState] = useState(0)
    const [pageOption, setPageOptionState] = useState()
    const [selections, setSelections] = useState([])
    const [firstPageSelection, setFirstPageSelection] = useState()

    if(selections.length !== precart.currentFileproOption.length) {
        let temp = []
        for(var s = 0; s < precart.currentFileproOption.length; s++)
            temp.push(-1)
        setSelections(temp)
    }

    let pages = []
    if(precart.currentFileproOption.length === 0) {
        pages.push({type: 'default', optionList: precart.fileproOptions})
    }
    else {
        for(var i = 0; i < precart.currentFileproOption.length; i++) {
            let optionList = []
            const current = i
            precart.fileproOptions.forEach((option) => {
                if(option.Type === precart.currentFileproOption[current])
                    optionList.push(option)
                if(option.Type.toLowerCase().includes('cargo') && precart.currentFileproOption[current] === 'CARGO')
                    optionList.push(option)
            })
            pages.push({type: precart.currentFileproOption[i], optionList: optionList})
        }
    }
    if(pages[0]?.optionList[firstPageSelection]?.Console && pages[0]?.optionList[firstPageSelection]?.Console !== '') {
        pages.push({
            type: "CONSOLE",
            optionList: [
                {
                    Description: 'Includes custom console cover that perfectly fits your vehicles center console.',
                    Note: 'Upgrade console - $' + upgradedConsoleCost.toFixed(2),
                    Image: '/img/styles/console-upgrade.jpg',
                    Item: pages[0].optionList[firstPageSelection].Console
                },
                {
                    Description: 'You will still receive a basic lid cover for your console.',
                    Note: 'No Thanks',
                    Image: '/img/styles/console-no.jpg'
                }
            ]
        })
    }

    const setPageOption = (pageOp) => {
        if(currentPage === 0) setFirstPageSelection(pageOp)
        setPageOptionState(pageOp)
    }

    const setCurrentPage = (pageVal) => {
        let temp = [...selections]
        temp[currentPage] = pageOption
        setSelections(temp)
        if(pageVal === 1 && currentPage === pages.length - 1)
            sendOrder()
        else {
            setPageOptionState()
            if(pageVal === -1) setCurrentPageState(currentPage - 1)
            else setCurrentPageState(currentPage + 1)
        }
    }

    const closeModal = () => {
        setCurrentPageState(0)
        setPageOption()
        setSelections([])
        precart.setShowPrecheck()
    }

    function sendOrder() {
        let order = []
        let addConsole = false
        for(var i = 0; i < pages.length; i++) {
            if(pages[i].type !== 'CONSOLE')
                order.push(pages[i].optionList[selections[i] >= 0 ? selections[i] : pageOption])
            else {
                if(pageOption === 0) addConsole = true
            }
        }
        if(addConsole) precart.addToCart(order, true)
        else precart.addToCart(order)
        closeModal()
        navigate('/shopping-cart/')
    }

    return (
        <Modal closeModal={closeModal}>
            <PrecheckHeader vehicle={precart.vehicle} product={product} />
            <PrecheckContent product={product} page={pages[currentPage]} vehicle={precart.vehicle} pageOption={pageOption} setPageOption={setPageOption} />
            <PrecheckButton valid={pageOption >= 0 } pageCount={pages.length} currentPage={currentPage} setCurrentPage={setCurrentPage} closeModal={closeModal} />
        </Modal>
    )
}