import { useContext } from "react";
import { ColorOptionStyle } from "../../../style";
import { PrecartContext } from "../../../../../providers/precartProvider";
import { THEMES } from "../../../../../data/themes.mjs";
import { defaultTheme } from "../../../../../config.mjs";

export function ColorOptions({options}) {
    const style = ColorOptionStyle()
    const precart = useContext(PrecartContext)
    const theme = THEMES[defaultTheme]

    return (
        <div style={style.container}>
            <div style={style.title}>Select Option - { options[precart.colorOption].name}</div>
            <div style={style.optionContainer}>
                { options.map((option, id) => {
                    return <div style={{...style.optionBorder, borderColor: precart.colorOption === id ? theme.specialBackground : "transparent"}} key={id}>
                    <div style={{...style.option, backgroundImage: `url(${option.preview})`}} onClick={() => precart.setColorOption(id)} />
                </div> })}
            </div>
        </div>
    );
  }

  