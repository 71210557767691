import { Link } from "react-router-dom"
import { HeroStyle } from "../../style"
import { website } from "../../../../config.mjs"

export default function HeroText({ landingData }) {
    const style = HeroStyle(landingData)
    
    return (
        <div style={style.textContainer}>
            { landingData.logoImage && landingData.logoImage !== '' &&
                <img style={style.mobileLogo} src={landingData.logoImage} alt={website + ' Logo'} title={website + ' Logo'} loading="eager" width="100%" height="auto" />
            }
            <h1 style={style.header}>{landingData.headerText}</h1>
            { landingData.subText !== '' && <h3 style={style.subText}>{landingData.subText}</h3> }
            <div />
            <div style={style.buttonContainer}>
                <Link to={'.' + landingData.buttonLink} style={style.button}>
                    {landingData.buttonText}
                </Link>
            </div>
        </div>
    )
}