import { useContext, useEffect, useState } from "react"
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg'
import { CartPageStyle, CartStyle } from "../style"
import { THEMES } from "../../../data/themes.mjs"
import { defaultTheme } from "../../../config.mjs"
import { CartContext } from "../../../providers/cartProvider"

export function Bag() {
    const cart = useContext(CartContext)
    const items = cart.items
    const cartPageStyle = CartPageStyle()
    const style = CartStyle()
    const theme = THEMES[defaultTheme]
    const [vehicle, setVehicle] = useState()
    const [deliveryDate, setDeliveryDate] = useState()

    useEffect(() => {
        let vehicleNames = []
        for(var i = 0; i < items.length; i++)
            vehicleNames.push(items[i].year + ' ' + items[i].make + ' ' + items[i].model)
        setVehicle(vehicleNames)

        const date = new Date(new Date().getTime()+(15*24*60*60*1000))
        setDeliveryDate(date.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}))
    }, [items, setVehicle])
    
    return (
        <div style={style.container}>
            <h1 style={cartPageStyle.title}>Shopping Cart</h1>
            { vehicle && (!items || items.length === 0) && <div style={style.noItems}>
                There are no items in your Shopping Cart
            </div> }
            { vehicle && items.length > 0 && items.map((prod, id) => {
                return <div key={id} style={{...style.itemContainer, borderBottom: `1px solid ${theme.borderColor}`}}>
                    <img style={style.image} src={prod.image} alt={vehicle[id]} title={vehicle[id]} loading="eager" width={window.innerWidth < 780 ? "114px" : "164px"} height={window.innerWidth < 780 ? "114px" : "164px"} />
                    <div style={style.detailsContainer}>
                        <div style={style.name}>{prod.productName} {prod.productType}</div>
                        <div style={style.details}>{vehicle[id]}</div>
                        <div style={style.details}>Item#: {prod.item}</div>
                        { prod.colorName && <div style={style.details}>{prod.upgradedConsole ? '' : `Color: ${prod.colorName}`}</div> }
                        { prod.row &&<div style={style.details}>{prod.upgradedConsole ? '' : `Row: ${prod.row}`}</div> }
                        <div style={style.details}>{prod.upgradedConsole ? '' : `Quantity: 1`}</div>
                    </div>
                    <div style={style.costContainer}>
                        <div style={style.cost}>${prod.price.toFixed(2)}</div>
                        <DeleteIcon style={style.delete} id={id} className={"h-text-" + theme.hoverColor} onClick={() => cart.removeItem(id)} />
                    </div>
                </div>
            })}
            { items.length > 0 && <>
                <div style={cartPageStyle.shippingTitle}>Shipping Information</div>
                <div style={cartPageStyle.shippingText}>Your shipping is FREE! Item requires 10-13 business days for production.</div>
                <div style={cartPageStyle.shippingTextDos}>Arrives on or before {deliveryDate}</div>
            </>}
        </div>
    )
}