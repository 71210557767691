import { website } from '../../../config.mjs';
import { HeaderStyle } from '../style';
import { useContext, useState } from 'react';
import { FontContext } from '../../../providers/fontProvider';
import { Link } from 'react-router-dom';

export default function Header({ landing }) {
    const style = HeaderStyle(landing)
    const fonts = useContext(FontContext)
    const [position, setPosition] = useState(0)

    window.addEventListener("scroll", () => {
        setPosition(window.scrollY)
    })

    function scrollTo(element) {
        document.querySelector(`#${element}`).scrollIntoView({ 
            behavior: 'smooth' 
        });
    }

    return (
        !fonts.mobile && <div style={style.container} id='header'>
            <div style={style.inner}>
                
                <div style={style.logoContainer} onClick={() => window.scrollTo(0, 0)}>
                    {(position > 300 || landing.header.showLogoAtStart) && <img height={fonts.mobile ? "40px" : "60px"} width="auto" alt={`${website} Logo`} src={landing.header.logoImage} loading="eager" title={`${website} Logo`} />}
                </div>

                <div style={style.linkContainer}>
                    { landing.sections.map((section, id) => {
                        if(section.headerLink) 
                            return <div key={id} onClick={() => scrollTo(section.headerLinkID)} style={style.link} className={'h-text-' + landing.header.hoverColor}>
                                { section.headerLinkText }
                            </div>
                        else return null
                    })}
                    <Link style={style.link} className={'h-text-' + landing.header.hoverColor} to='./contact/'>
                        Contact Us
                    </Link>
                </div>
            </div>
        </div>
    )
}